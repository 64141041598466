import styled from "@emotion/styled"
import {Button, ButtonProps, Card, CardContent, Grid, Paper, Theme, Tooltip} from "@material-ui/core"
import * as React from "react"
import {PropsWithChildren} from "react"
import consoles from "../../data/images/index/allgames.svg"
import eventBG from "../../data/images/index/event-bg.png"
import recalbox8VideoMP4 from "../../data/videos/9.2/9.2.mp4"
import recalbox8VideoWEBM from "../../data/videos/9.2/9.2.webm"
import recalboxLogoText from "../../data/images/recalbox-text-only-simplesvg.svg"
import logoRecalboxRGB from "../../data/images/index/recalbox-rgb-logo-color.svg"
import videoThumbnail from "../../data/images/index/video-thumb.png"

import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {ExternalLink, ResponsiveContainer, StarsImageContainer} from "../../components/Shared"
import {useTheme} from "@emotion/react"
import {Diy1Discover} from "../diy/Diy1Discover"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {TextCenteredGrid} from "../diy/components/Shared"
import {ChannelPage} from "../download/ChannelPage"
import {RecalboxLinks} from "../links/LinksPage"
import {RecalboxRGBDualOrderPage} from "../rgbdual/order/RecalboxRGBDualOrder";
import {useI18nLink} from "../../components/i18n/I18nLink";
import {BoardPage} from "../download/BoardPage";
import {CategoryPage} from "../download/CategoryPage";


const JumboSvg = styled.img`
  ${({theme}) => `
  max-width: 60%;
  margin: 0 auto ${theme.spacings.xl} auto;
  display: block;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 80%;
    }
`}
`

const JumboContainer2 = styled.div`
  ${({theme}) => `
  margin-top: ${theme.spacings.md};
  margin-bottom: ${theme.spacings.xxl};
  color: white;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-top: 4rem;
  }
`}
`

const AnnouncementContainer = styled.div`
  ${({theme}) => `
  margin-top: ${theme.spacings.md};
  color: white;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-top: 4rem;
  }
`}
`

const CTAContainer = styled.div(({theme}) => ({
  marginBottom: theme.spacings.xxl
}))

const CTACard = styled(Paper)`
  ${({theme}) => `
  margin: 0 auto;
  padding: ${theme.spacings.lg};
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: 75%;
  }
  text-align: center;
`}
`

const CTACardTitle = (props: PropsWithChildren<any>) => {
  return <TransMdTypo variant={"h2"} gutterBottom css={{fontSize: "1.8rem", fontWeight: "bold"}}
                      children={props.children}/>
}

const CTACardActions = styled.div(({theme}) => ({}))


const CTAButton = styled(Button)((props: ButtonProps & { theme?: Theme, to?: string, component?: any }) => ({
  margin: "0 auto",
  marginTop: props.theme!.spacings.md,
}))

const JumboVideo = () => {
  const theme = useTheme()
  return (
    <div>
      <video muted loop controls poster={videoThumbnail} autoPlay
             css={{width: "100%", height: "100%", objectFit: "contain", borderRadius: `${theme.shape.borderRadius}px`}}>
              <source src={recalbox8VideoWEBM} type="video/webm"/>
              <source src={recalbox8VideoMP4} type="video/mp4"/>
      </video>
    </div>
  )
}

const LogoImg = styled.img(({theme}) => ({
  maxWidth: "100%",
  marginBottom: theme.spacing(2)
}))

export const AnnouncementButton = styled(Button)((props: { theme?: Theme, to?: string, component?: any }) => ({
  fontWeight: 700,
  borderWidth: "2px",
  margin: "0.7em 0 0 0",
}))

export const AnnouncementCard = styled(Card)(({theme}) => ({
  //backgroundImage: "linear-gradient(to right, #1BB13E, #C1E960)",
  backgroundImage: "linear-gradient(to right, #13802c, #93b34b)",
  overflow: "visible"
}))

const AnnouncementLeftImage = styled.img(() => ({
  position: "absolute",
  bottom: "0",
  left: "-30px",
  overflow: "display",
  maxHeight: "140%",
  maxWidth: "150px"
}))

const AnnouncementTextGrid = styled(TextCenteredGrid)(({theme}) => ({
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    marginLeft: "110px"
  }
}))

const SocialIcon = styled.span((props: { theme?: Theme, color?: string }) => ({
  overflow: "hidden",
  fontSize: "1.5em",
  marginRight: "0.5em",
  color: props.theme!.palette.text.primary,
  transition: "0,5s all",
  "& svg:hover": {
    color: props.color ?? props.theme!.palette.text.primary,
    transform: "scale(1.2)"
  }
}))

const SocialNetworks = () => {
  const {i18n} = useTranslation()
  return <TextCenteredGrid container justify={"center"} css={{marginTop: "0.5em"}}>
    {RecalboxLinks.map((link) => {
      if (link.socialnetwork) {
        if (link.only && link.only !== i18n.language) return
        if (link.except && link.except === i18n.language) return
        return <Grid item>
          <ExternalLink href={link.link}>
            <Tooltip title={link.title} arrow>
              <SocialIcon color={link.color}>
                {link.icon}
              </SocialIcon>
            </Tooltip>
          </ExternalLink>
        </Grid>
      }
    })}
  </TextCenteredGrid>
}
const JUMBO_TYPE = process.env.GATSBY_JUMBO_TYPE
const GATSBY_SCART_DISCOUNT = process.env.GATSBY_SCART_DISCOUNT == "true"

export const Jumbo = (props: { video?: boolean }) => {
  const {t} = useTranslation()
  return (
    <React.Fragment>
      <StarsImageContainer>
        <ResponsiveContainer>
          <AnnouncementContainer>
            {/*{JUMBO_TYPE === "8.1" ?
              <AnnouncementCard>
                <div css={{backgroundImage: `url(${eventBG})`}}>
                  <CardContent css={{position: "relative"}}>
                    <AnnouncementLeftImage src={logoRecalboxRGB}/>
                    <AnnouncementTextGrid>
                      <TransMdTypo variant={"h3"}>
                        home^Recalbox 8.1 is available
                      </TransMdTypo>
                      <AnnouncementButton variant={"outlined"} component={Link}
                                          to={"/blog/"}>
                        {t("home^Read more...")}
                      </AnnouncementButton>
                    </AnnouncementTextGrid>
                  </CardContent>
                </div>
              </AnnouncementCard>
              : (JUMBO_TYPE === "patreon" ?
              <AnnouncementCard>
                <div css={{backgroundImage: `url(${eventBG})`}}>
                  <CardContent css={{position: "relative"}}>
                    <AnnouncementLeftImage src={announcementPatreon}/>
                    <AnnouncementTextGrid>
                      <StaticImage src={"../../data/images/index/recalboxxpatreon.png"} alt={"Recalbox X Patreon"}/>
                      <TransMdTypo variant={"h5"} css={{color: "white"}}>
                        patreon^Recalbox needs you !
                      </TransMdTypo>
                      <TransMdTypo>
                        patreon^Support the Recalbox project on Patreon, and decide on the features of tomorrow!
                      </TransMdTypo>
                      <AnnouncementButton variant={"outlined"} component={Link}
                                          to={PatreonPage.getUrl()}>
                        {t("home^Read more...")}
                      </AnnouncementButton>
                    </AnnouncementTextGrid>
                  </CardContent>
                </div>
              </AnnouncementCard>
               :*/}
                <AnnouncementCard>
                  <div css={{backgroundImage: `url(${eventBG})`}}>
                    <CardContent css={{position: "relative"}}>
                      <AnnouncementLeftImage src={logoRecalboxRGB}/>
                      <AnnouncementTextGrid>
                        <TransMdTypo variant={"h2"}>
                          rrgbdorder^Recalbox RGB JAMMA and DUAL are back in **our new shop!**
                        </TransMdTypo>
                        <TransMdTypo>
                          rrgbdorder^Get yours now!
                        </TransMdTypo>
                        <AnnouncementButton variant={"outlined"} target={"_blank"}
                                            href={"https://shop.recalbox.com"}>
                          {t("rrgbdorder^Go to the shop")}
                        </AnnouncementButton>
                      </AnnouncementTextGrid>
                    </CardContent>
                  </div>
                </AnnouncementCard>

            <SocialNetworks/>
          </AnnouncementContainer>
          <JumboContainer2>
            <Grid container spacing={3}>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12} md={6}>
                {props.video ?
                  <JumboVideo/>
                  :
                  <JumboSvg src={consoles}/>
                }
              </Grid>
              <Grid item xs={12} md={6}>
                <LogoImg src={recalboxLogoText} alt={"recalbox"}/>
                <TransMdTypo variant="h1" gutterBottom>home^The all in one retrogaming console!</TransMdTypo>
                <TransMdTypo>home^Play again to all your consoles!</TransMdTypo>
              </Grid>
            </Grid>

          </JumboContainer2>
          <CTAContainer>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CTACard elevation={2}>
                  <CTACardTitle>
                    {t("home^I want it!")}
                  </CTACardTitle>
                  <CTACardActions>
                    <TransMdTypo>home^We help you in four steps</TransMdTypo>
                    <div>
                      <CTAButton component={Link} to={Diy1Discover.getUrl()} variant="contained" color="primary">
                        {t("home^Go to step 1")}
                      </CTAButton>
                    </div>
                  </CTACardActions>
                </CTACard>
              </Grid>
              <Grid item xs={12} md={6}>
                <CTACard elevation={2}>
                  <CTACardTitle>
                    {t("home^I already have hardware")}
                  </CTACardTitle>
                  <CTACardActions>
                    <TransMdTypo>
                      home^Download and install last version
                    </TransMdTypo>
                    <div>
                      <CTAButton variant="contained" color="secondary" component={Link}
                                 to={ChannelPage.getUrl()}>
                        {t("home^Download")}
                      </CTAButton>
                    </div>
                  </CTACardActions>
                </CTACard>
              </Grid>
            </Grid>
          </CTAContainer>
        </ResponsiveContainer>
      </StarsImageContainer>
    </React.Fragment>
  )
}

